import { FC, useCallback, useMemo } from 'react';
import { ModuleDefaultConfiguration } from '../../models/ClientModuleDefaults';
import { AccessTypeKeys } from '../../models/Access';
import AccessDropdown from '../shared/AccessDropdown';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';
import { currentClientAtom } from '../../recoil/atoms/Clients';
import { ClientFormUser } from '../../models/ClientFormUser';
import AccessControl from '../ownership/AccessControl';
import User from '../../models/User';
import UserInvitation from '../../models/UserInvitation';

type CreateAccessProps = {
  defaults: ModuleDefaultConfiguration;
  onDefaultsChange: (defaults: ModuleDefaultConfiguration) => void;
  clientUsers: User[];
  requireValidator?: boolean;
  requireApprover?: boolean;
  requireOwner?: boolean;
  inviteUserMode?: boolean;
  onUserInviteChange?: (user: UserInvitation) => void;
  onUserInviteValid?: (isValid: boolean) => void;
};

const ManageAccess: FC<CreateAccessProps> = (props) => {
  const {
    defaults,
    onDefaultsChange,
    clientUsers,
    requireValidator,
    requireApprover,
    requireOwner,
    inviteUserMode,
    onUserInviteChange,
    onUserInviteValid,
  } = props;
  const { t } = useTranslation(['documents']);
  const currentClient = useRecoilValue(currentClientAtom);

  const configValueChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (key: keyof ModuleDefaultConfiguration, value: any) => {
      onDefaultsChange({ ...defaults, [key]: value });
    },
    [defaults, onDefaultsChange],
  );

  const assignedUsers = useMemo((): ClientFormUser[] => {
    if (defaults && clientUsers.length) {
      const users = Object.entries(defaults?.users || []).map(([key, value]) => {
        const clientUser = clientUsers.find((user) => user.id && user.id === key)!;
        return { ...clientUser, role: value };
      });
      return users;
    }
    return [];
  }, [clientUsers, defaults]);

  const onUsersChange = useCallback(
    (users: ClientFormUser[]) => {
      configValueChange('users', Object.assign({}, ...users.map((x) => ({ [x.id || '']: x.role }))));
    },
    [configValueChange],
  );

  return (
    <>
      {!inviteUserMode && (
        <>
          <div className="relative py-4">
            <AccessDropdown selectedAccess={defaults.accessType} onChange={(value) => configValueChange('accessType', value)} />
            <div className="pl-6">{t(AccessTypeKeys[defaults.accessType], { client: currentClient?.name })}</div>
          </div>
          <div className="text-dpm-16 pb-4 font-medium">{t('access.default-members')}</div>
        </>
      )}
      <AccessControl
        users={assignedUsers}
        onUsersChange={onUsersChange}
        requireValidator={requireValidator}
        requireApprover={requireApprover}
        requireOwner={requireOwner}
        inviteMode={inviteUserMode}
        onUserInviteChange={onUserInviteChange}
        onUserInviteValid={onUserInviteValid}
      />
    </>
  );
};

export default ManageAccess;
